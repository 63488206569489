import React, { ReactNode, useEffect } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';

import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const RoleSelect = ({ onChange, ...rest }: SelectProps) => {
  const { user } = useStore();

  useEffect(() => {
    fetchDefaultOptions();
  }, []);

  const getOptions = () => {
    if (user.roles) {
      return Object.keys(user.roles);
    }
    return [];
  };

  const fetchDefaultOptions = async () => {
    await user.getRoles();
  };

  const handleChange = (value, option): void => {
    onChange(value, option);
  };

  const renderOption = (name): ReactNode => {
    if (name) {
      return (
        <Select.Option key={name} value={name}>
          {user.roles[name]}
        </Select.Option>
      );
    }
    return null;
  };

  const options = getOptions();

  return (
    <Select
      onChange={handleChange}
      getPopupContainer={(el) => el}
      placeholder={translate('enterRole')}
      {...rest}
      {...DEFAULT_SELECT_PROPS}
    >
      {options.map(renderOption)}
    </Select>
  );
};

export default observer(RoleSelect);
