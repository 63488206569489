import React, { ReactNode, forwardRef, useEffect } from 'react';

import { Select, Tooltip } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { useParams } from 'react-router';

import { getUTCTimezoneFromName } from 'helpers/date';
import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { translate } from 'modules/localization';
import { IServiceZones } from 'modules/service-zones/models/types';
import { IWarehouses } from 'modules/warehouses/models/types';
import { useStore } from 'services/store';

interface IParams {
  locationGuid: string;
}

interface IProps {
  isTooltipVisible?: boolean;
  withTooltip?: boolean;
}

const ServiceZonesSelect = forwardRef<BaseSelectRef, IProps & SelectProps>(
  ({ isTooltipVisible, withTooltip, ...rest }: IProps & SelectProps, ref) => {
    const { serviceZones } = useStore();

    const { locationGuid } = useParams<IParams>();

    const getOptions = () => {
      const options = serviceZones.serviceZonesList;
      if (options && Array.isArray(options)) {
        return options;
      }
      return [];
    };

    const fetchDefaultOptions = async (): Promise<void> => {
      await serviceZones.getServiceZones(
        { pageSize: 100, current: 1, locationGuid: locationGuid },
        false,
      );
    };

    useEffect(() => {
      fetchDefaultOptions();
    }, []);

    const getTimezonesTooltipTitle = (warehouses: IWarehouses[]): string =>
      warehouses ? warehouses.map((w) => getUTCTimezoneFromName(w.timezone)).join(' ,') : null;

    const getTimezonesForServiceZone = (serviceZoneGuid: string): string => {
      const currentOption = getOptions().find((o) => o.guid === serviceZoneGuid);
      const warehouses = currentOption && currentOption.warehouses;
      return warehouses
        ? warehouses.map((w) => getUTCTimezoneFromName(w.timezone)).join(' ,')
        : null;
    };

    const onOptionMouseEnterHandler = (guid: string): void => {
      serviceZones.setHoveredCoveragesByZone(guid);
    };

    const onOptionMouseLeaveHandler = (): void => {
      serviceZones.setHoveredCoveragesByZone(null);
    };

    const renderOption = (option: IServiceZones): ReactNode => {
      if (option) {
        return withTooltip ? (
          <Select.Option
            key={option.guid}
            value={option.guid}
            onMouseEnter={(): void => onOptionMouseEnterHandler(option.guid)}
            onMouseLeave={(): void => onOptionMouseLeaveHandler()}
          >
            <Tooltip title={getTimezonesTooltipTitle(option.warehouses)} placement="bottomLeft">
              {option.name || option.guid} {option.disabled ? ` (${translate('disabled')})` : ''}
            </Tooltip>
          </Select.Option>
        ) : (
          <Select.Option
            key={option.guid}
            value={option.guid}
            onMouseEnter={(): void => onOptionMouseEnterHandler(option.guid)}
            onMouseLeave={(): void => onOptionMouseLeaveHandler()}
          >
            {option.name}
          </Select.Option>
        );
      }

      return null;
    };

    const options = getOptions();

    return (
      <Tooltip
        title={rest && rest.value ? getTimezonesForServiceZone(rest.value) : null}
        placement="bottomLeft"
        open={
          rest && rest.value && getTimezonesForServiceZone(rest.value) ? isTooltipVisible : null
        }
      >
        <div style={{ width: '100%' }}>
          <Select
            showSearch
            allowClear
            ref={ref}
            loading={serviceZones.isLoadingServiceZonesList}
            filterOption={selectFilter}
            defaultActiveFirstOption={false}
            getPopupContainer={(el) => el}
            {...rest}
            {...DEFAULT_SELECT_PROPS}
          >
            {options.map(renderOption)}
          </Select>
        </div>
      </Tooltip>
    );
  },
);

export default observer(ServiceZonesSelect);
