import { IIdentityForm, IProfile, IProfileForm, IUserForm } from 'modules/user/models/types';

export const prepareProfileData = (formData): IProfileForm => {
  const { surname, name, patronymic, email, phone, disabled, roles, warehouses, shops } = formData;
  return {
    surname: surname ? surname.trim() : null,
    name: name ? name.trim() : null,
    patronymic: patronymic ? patronymic.trim() : null,
    email: email ? email.trim() : null,
    phone: phone ? phone.trim() : null,
    disabled: !disabled,
    roles: roles ? [roles] : [],
    warehouses: warehouses ? warehouses : [],
    shops: shops ? shops : [],
  };
};

export const prepareIdentityData = (formData): IIdentityForm => {
  const { login, password } = formData;
  return {
    login: login ? login.trim() : null,
    password: password ? password.trim() : null,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDataToSet = (data: IProfile): IUserForm | Record<string, any> => ({
  surname: data.surname,
  name: data.name,
  patronymic: data.patronymic,
  email: data.email,
  phone: data.phone,
  disabled: !data.disabled,
  login: data.identity ? data.identity.login : '',
  password: null,
  passwordConfirm: null,
  roles: data.roles ? data.roles[0] : undefined,
  warehouses: data.warehouses ? data.warehouses.map((m) => m.guid) : undefined,
  shops:
    data.meta && data.meta.shops && Array.isArray(data.meta.shops)
      ? data.meta.shops.map((item) => item.guid)
      : undefined,
});
