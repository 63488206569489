import './style.less';

import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useDrag, useDrop } from 'react-dnd';

import classSet from 'helpers/classSet';
import CourierModal from 'modules/routeGenerator/components/common/CourierModal/CourierModal';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { DndTypes, IDndItem } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import { getTasksListVolume, getTasksListWeight } from 'modules/tasks/helpers/lists';
import { useStore } from 'services/store';

import Counter from './Counter';
import Courier from './Courier';
import Coverages from './Coverages';
import FullnessProgress from './FullnessProgress';
import Timeslot from './Timeslot';

interface IProps {
  cardData: IRouteDraft;
  serialNumber: number;
  routeId: string;
  isList?: boolean;
}

interface IDndProps {
  hovered: boolean;
}

const RouteCard = ({ cardData, serialNumber, routeId, isList }: IProps) => {
  const { routeGenerator } = useStore();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DndTypes.ROUTE,
    item: {
      id: cardData.uid,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ hovered }, drop] = useDrop<IDndItem, void, IDndProps>(() => ({
    accept: [DndTypes.LEFT_COVERAGE, DndTypes.LEFT_TASK, DndTypes.ROUTE],
    drop: (item, monitor) =>
      routeGenerator.dndStore.onDropToRoute(item.id, monitor.getItemType(), routeId),
    collect: (monitor) => {
      return {
        hovered: monitor.isOver(),
      };
    },
  }));

  const [isModalOpened, toggleModal] = useState(false);

  const openCardInfo = () => {
    routeGenerator.setOpenCardRouteDraftId(routeId);
  };

  const closeModal = (): void => toggleModal(false);

  const openModal = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    toggleModal(true);
  };

  const scrollIntoViewRef = useRef(null);
  const scrollIntoView = () => {
    scrollIntoViewRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollIntoView();
  }, [cardData.isScrollableIntoView]);

  const tasksWeight = getTasksListWeight(cardData.tasksList);
  const tasksVolume = getTasksListVolume(cardData.tasksList);

  const weightPercent = cardData.transport ? (tasksWeight * 100) / cardData.transport.weight : 0;
  const volumePercent = cardData.transport ? (tasksVolume * 100) / cardData.transport.volume : 0;

  return (
    <>
      <div
        className={classSet({
          'rg-routecard': true,
          'rg-routecard--courier': Boolean(cardData.courierGuid),
          'rg-routecard--hovered': hovered,
          'rg-routecard--highlighted': isList && cardData.isHighlighted,
          'rg-routecard--overload': weightPercent > 100 || volumePercent > 100,
          'rg-routecard--last-interact': routeId === routeGenerator.lastInteractRouteID,
        })}
        onClick={openCardInfo}
        data-testid="generator-routeCard"
        ref={(node) => drag(drop(node))}
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        {cardData.error ? <div className="rg-routecard__error">{cardData.error}</div> : null}
        <div className="rg-routecard--wrapper">
          <div
            className="rg-routecard__left"
            ref={isList && cardData.isScrollableIntoView ? scrollIntoViewRef : null}
          >
            <Courier openModal={openModal} cardData={cardData} number={serialNumber} />
            <FullnessProgress cardData={cardData} />
          </div>
          <div className="rg-routecard__right">
            <Coverages coverages={cardData.coverages} />
            <div className="rg-routecard__counter-box">
              <Counter tasksList={cardData.tasksList} />
              <Timeslot cardData={cardData} date={routeGenerator.routeSettings.deliveryDate} />
            </div>
          </div>
        </div>
      </div>

      <CourierModal
        isModalOpened={isModalOpened}
        routeId={routeId}
        closeModal={closeModal}
        initialValues={cardData}
      />
    </>
  );
};

export default observer(RouteCard);
