import './style.less';

import React, { ReactNode, useEffect, useState } from 'react';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps, Timeline } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import classSet from 'helpers/classSet';
import { formatCurrency } from 'helpers/currency';
import { getStatusIconImg } from 'helpers/statusIcon';
import { dateFormat, timeFormat } from 'helpers/string';
import InfoFact from 'modules/common/components/InfoBlock/InfoFact';
import InfoSection from 'modules/common/components/InfoBlock/InfoSection';
import { translate } from 'modules/localization';
import { getCourierUrl } from 'modules/routes/components/RoutesList/helpers';
import { IRoute } from 'modules/routes/models/types';
import { groupTasksWithRelativesRouteInfo } from 'modules/tasks/helpers/lists';
import { ITask } from 'modules/tasks/models/types';
import { getSize, getAmount as getTaskAmount } from 'modules/tasks/models/utils';
import { IProfile } from 'modules/user/models/types';
import { useStore } from 'services/store';

import RenderTasksTimeline from './RenderTasksTimeline';

interface IProps {
  routeUpdate: () => Promise<void>;
  route: IRoute;
}

const RouteInfo = ({ route, routeUpdate }: IProps) => {
  const { tasks, user, routes, currency, warehouses } = useStore();

  const [creator, setCreator] = useState<IProfile>(null);

  useEffect(() => {
    route.creator_guid && user.get(route.creator_guid).then(setCreator);
  }, [route.creator_guid]);

  useEffect(() => {
    tasks.apiStore.getTasksStatuses();
    tasks.apiStore.getTasksProblems();
    tasks.apiStore.getTasksTypes();
  }, []);

  const [currencyCode, setCurrencyCode] = useState<string>(null);

  useEffect(() => {
    route &&
      route.start_warehouse &&
      warehouses
        .get(route.start_warehouse.guid)
        .then((w) => setCurrencyCode(w?.shops[0]?.currency));
  }, [route.start_warehouse]);

  const tasksGroupedByRelative = groupTasksWithRelativesRouteInfo(route.tasks);

  const renderTaskListHeader = (): ReactNode => {
    const tasksCount = (route.tasks && route.tasks.length) || 0;

    if (tasksCount === 0) {
      return <div className="route-info-header">{translate('noTasks')}</div>;
    }

    const addressCount = tasksGroupedByRelative.length;

    return (
      <div className="route-info-header">
        <span>
          {addressCount} {translate('address', { count: addressCount })}
          {','}{' '}
          <span className="route-info-header__tasks-count">
            {tasksCount} {translate('task', { count: tasksCount })}
          </span>
        </span>
        <span className="route-info-header__info-message">{translate('planActual')}</span>
      </div>
    );
  };

  const timeStart = route.date_time_start || route.date_time_planned_start;
  const timeEnd = route.date_time_finish || route.date_time_planned_finish;

  const differenceOfDayPlanned =
    dayjs(timeStart).format(dateFormat.string) !== dayjs(timeEnd).format(dateFormat.string)
      ? `${dayjs(route.date_time_planned_start).format('DD.MM')} \u2014 ${dayjs(
          route.date_time_planned_finish,
        ).format('DD.MM')}`
      : dayjs(route.date_time_planned_start).format('DD.MM');
  const differenceOfDay =
    dayjs(route.date_time_start).format(dateFormat.string) !==
    dayjs(route.date_time_finish).format(dateFormat.string)
      ? `${dayjs(route.date_time_start).format('DD.MM')} \u2014 ${dayjs(
          route.date_time_finish,
        ).format('DD.MM')}`
      : dayjs(route.date_time_start).format('DD.MM');

  const renderDateTimeFact = () =>
    route.date_time_start &&
    route.date_time_finish &&
    route.start_warehouse && (
      <>
        {differenceOfDay}
        {' /'}{' '}
        {dayjs(route.date_time_start).tz(route.start_warehouse.timezone).format(timeFormat.simple)}{' '}
        &mdash;{' '}
        {dayjs(route.date_time_finish)
          .tz(
            route.finish_warehouse
              ? route.finish_warehouse.timezone
              : route.start_warehouse.timezone,
          )
          .format(timeFormat.simple)}
      </>
    );

  const renderDateTimePlan = () =>
    route.date_time_planned_start &&
    route.date_time_planned_finish &&
    route.start_warehouse && (
      <>
        {differenceOfDayPlanned}
        {' /'}{' '}
        {dayjs(route.date_time_planned_start)
          .tz(route.start_warehouse.timezone)
          .format(timeFormat.simple)}{' '}
        &mdash;{' '}
        {dayjs(route.date_time_planned_finish)
          .tz(
            route.finish_warehouse
              ? route.finish_warehouse.timezone
              : route.start_warehouse.timezone,
          )
          .format(timeFormat.simple)}
      </>
    );

  const getAmount = (): number => {
    return route.tasks.reduce((sum, item) => sum + getTaskAmount(item), 0);
  };

  const getWeight = (): number => {
    return route.tasks.reduce((sum, item) => sum + getSize('weight', item), 0);
  };

  const getVolume = (): number => {
    return route.tasks.reduce((sum, item) => sum + getSize('volume', item), 0);
  };

  const renderSpoilerHeader = () => (
    <div className="route-info-main__header">
      {route.session && route.session.courier ? (
        <Link
          to={getCourierUrl(route.session.courier_guid, route.session.planned_date)}
          className="route-info-main__courier-link"
        >
          {`${route.session.courier.profile.surname} ${route.session.courier.profile.name.substring(
            0,
            1,
          )}. ${
            route.session.courier.profile.patronymic
              ? route.session.courier.profile.patronymic.substring(0, 1) + '.'
              : ''
          }`}
        </Link>
      ) : null}
      <span>
        {route.session && route.session.transport
          ? `${route.session.transport.name} ${route.session.transport.number}`
          : null}
      </span>
      <p className="route-info-main__date-time">
        <ClockCircleOutlined />{' '}
        {route.date_time_start && route.date_time_finish
          ? renderDateTimeFact()
          : renderDateTimePlan()}
      </p>
    </div>
  );

  const renderSpoilerBody = () => (
    <div className="route-info-main__body">
      <InfoSection>
        <InfoFact header={translate('routePlannedDateTime')}>{renderDateTimePlan()}</InfoFact>
        <InfoFact header={translate('routeRealDateTime')}>{renderDateTimeFact()}</InfoFact>
      </InfoSection>
      <InfoSection>
        <InfoFact header={`${translate('sum')} / ${translate('weight')} / ${translate('volume')}`}>
          {route.tasks ? (
            <>
              {formatCurrency(getAmount(), currencyCode, currency.currencyList)}
              {' / '}
              {getWeight().toFixed(2)}&nbsp;{translate('weightUnit')}
              {' / '}
              {getVolume().toFixed(3)}&nbsp;{translate('lengthUnit')}
              <sup>{'3'}</sup>
            </>
          ) : null}
        </InfoFact>
        <InfoFact header={translate('creator')}>
          {creator
            ? `${creator.surname} ${creator.name.substring(0, 1)}. ${
                creator.patronymic ? creator.patronymic.substring(0, 1) + '.' : ''
              }`
            : translate('automatic')}
        </InfoFact>
      </InfoSection>
      <InfoSection>
        <InfoFact header={`${translate('distance')}`}>
          {routes.visibleRouteDistance
            ? `${routes.visibleRouteDistance} ${translate('distanceUnit')}`
            : ''}
        </InfoFact>
      </InfoSection>
    </div>
  );

  const routeInfoCollapse: CollapseProps['items'] = [
    {
      key: '1',
      label: renderSpoilerHeader(),
      children: renderSpoilerBody(),
    },
  ];

  const getTaskIcon = (item?: ITask, content?: number | string): ReactNode => {
    const isProblem = item?.problems && item?.problems.length > 0;
    return getStatusIconImg({
      status: isProblem ? `${item?.status}_problem` : item?.status,
      content,
    });
  };

  const timeline =
    tasksGroupedByRelative &&
    tasksGroupedByRelative.map((task, index) => ({
      children: <RenderTasksTimeline task={task} key={index} routeUpdate={routeUpdate} />,
      dot: <span className="route__point">{getTaskIcon(task, index + 1)}</span>,
    }));

  const startTimezone = route?.start_warehouse?.timezone;

  const timelineWarehouseStart = route.date_time_planned_start && {
    children: (
      <div className="route__point-warehouse">
        <div style={{ marginLeft: '10px' }}>{translate('loadingAtWarehouse')}</div>
        <div>
          <p>
            <span className="route-task-planned-time">
              {dayjs(route.date_time_planned_start).tz(startTimezone).format('HH:mm')}
            </span>
            <span
              className={classSet({
                'route-task-planned-time': true,
                'route-task-time-real ': !!route.date_time_start,
              })}
              style={{ marginRight: '10px' }}
            >
              {` / ${
                route.date_time_start
                  ? dayjs(route.date_time_start).tz(startTimezone).format('HH:mm')
                  : '--:--'
              }`}
            </span>
          </p>
        </div>
      </div>
    ),
    dot: <span className="route__point">{getTaskIcon()}</span>,
  };

  const finishTimezone = route?.finish_warehouse?.timezone || startTimezone;

  const timelineWarehouseFinish = route.date_time_planned_start && {
    children: (
      <div className="route__point-warehouse">
        <div style={{ marginLeft: '10px' }}>{translate('completingRoute')}:</div>
        <div>
          <p>
            <span className="route-task-planned-time">
              {' '}
              {dayjs(route.date_time_planned_finish).tz(finishTimezone).format('HH:mm')}
            </span>
            <span
              className={classSet({
                'route-task-planned-time': true,
                'route-task-time-real ': !!route.date_time_finish,
              })}
            >
              {` / ${
                route.date_time_finish
                  ? dayjs(route.date_time_finish).tz(finishTimezone).format('HH:mm')
                  : '--:--'
              }`}
            </span>
          </p>
        </div>
      </div>
    ),
    dot: <span className="route__point">{getTaskIcon()}</span>,
  };

  const timelineItems = [timelineWarehouseStart, ...timeline, timelineWarehouseFinish];

  return (
    <>
      <div className="route-info-main-wrap">
        <Collapse
          expandIconPosition="end"
          bordered={false}
          className="route-info-main"
          items={routeInfoCollapse}
        />
      </div>

      <div className="task__timeline">
        {renderTaskListHeader()}
        <Timeline items={timelineItems} />
      </div>
    </>
  );
};

export default observer(RouteInfo);
