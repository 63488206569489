import dayjs, { Dayjs } from 'dayjs';
import uniqueid from 'lodash.uniqueid';

import { ITimeslotUI } from 'modules/schedules/models/uitypes';

export interface IPresetFormValues {
  start: Dayjs;
  end: Dayjs;
  interval: string;
  limit: string;
  price: string;
}

export const getTimeslotsList = (values: IPresetFormValues): ITimeslotUI[] => {
  const { start: timeStart, end: timeEnd, interval, limit, price } = values;
  const list: ITimeslotUI[] = [];

  let dayjsStart = dayjs(timeStart).second(0);
  const dayjsEnd = dayjs(timeEnd).second(0);

  while (dayjsStart.format('X') < dayjsEnd.format('X')) {
    const start = dayjsStart.format('HH:mm');
    dayjsStart = dayjsStart.add(+interval, 'minutes');
    const end =
      dayjsStart.format('X') < dayjsEnd.format('X')
        ? dayjsStart.format('HH:mm')
        : dayjsEnd.format('HH:mm');

    const timeslot: ITimeslotUI = {
      start: dayjs(start, 'HH:mm'),
      end: dayjs(end, 'HH:mm'),
      limit: +limit || null,
      price: +price || null,
      id: uniqueid(),
      call: false,
    };

    list.push(timeslot);
  }

  return list;
};
